import React from "react";
import "./service.css";
import classNames from "classnames";
import useSticky from "../useSticky";
import Slider from "../slider/slider";
import Footer from "../footer/footer";
import Header from "../header/header";
import { features, service } from "../data";
import { Typography } from "@mui/material";
const Service = () => {
  const { sticky, stickyRef } = useSticky();
  return (
    <>
      <nav ref={stickyRef} className={classNames("nav", { sticky })}>
        <Header />
      </nav>
      <div
        style={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
        }}
      />
      <div style={{ height: "100vh" }}>
        <Slider  bg={"./image/service.png"} />
      </div>

      <section className="dalazu_service feature-area pt-125 pb-55">
        {service.map((item, index) => {
          console.log(item);
          return (
            <div className="container">
              <Typography
                textAlign={"center"}
                variant="h3"
                style={{
                  background:
                    "-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {item.title}
              </Typography>
              <div
                className={`row `}
                style={{
                  flexDirection: `${index % 2 === 0 ? "row-reverse" : ""}`,
                  marginTop: 100,
                }}
                key={index}
              >
                <div className="col-lg-7">
                  <div className="feature-social">
                    <ul className="feature-list-4 feature-socia-list">
                      <li>
                        <div className="feature-list-4-item p-relative d-flex">
                          <div className="feature-list-4-icon ">
                            <div className="feature-list-bg p-relative">
                              <i>
                                <svg
                                  width={44}
                                  height={40}
                                  viewBox="0 0 44 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g style={{ mixBlendMode: "multiply" }}>
                                    <path
                                      d="M4.87714 18.8519C-4.16478 32.8161 8.76877 40 19.6928 40C30.4338 40 42.5804 35.9136 42.5804 23.3559C42.575 -8.96311 20.3949 -5.10428 4.87714 18.8519Z"
                                      fill="#4260FF"
                                    />
                                  </g>
                                  <path
                                    d="M1.09435 32.563C1.05346 32.563 1.01347 32.5509 0.979401 32.5281C0.945334 32.5053 0.918713 32.4729 0.902885 32.4349C0.887057 32.3968 0.882728 32.355 0.890444 32.3145C0.898161 32.274 0.917578 32.2367 0.946252 32.2073L9.1723 23.9395C9.21224 23.9178 9.25821 23.9101 9.30298 23.9176C9.34774 23.9251 9.38877 23.9474 9.4196 23.981C9.45043 24.0145 9.46932 24.0575 9.4733 24.1031C9.47727 24.1487 9.46611 24.1943 9.44156 24.2328L1.24245 32.5033C1.22298 32.5227 1.19985 32.538 1.17441 32.5483C1.14897 32.5585 1.12174 32.5635 1.09435 32.563Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M34.1085 20.2052C34.082 20.2053 34.0557 20.2001 34.0312 20.1899C34.0067 20.1796 33.9844 20.1645 33.9658 20.1455C33.9276 20.1064 33.9063 20.0537 33.9062 19.9989C33.9063 19.944 33.9276 19.8914 33.9658 19.8522C34.3131 19.4911 42.4826 11.0306 43.1962 10.55C43.2418 10.5237 43.2957 10.5159 43.3469 10.5281C43.398 10.5403 43.4427 10.5717 43.4717 10.6159C43.5008 10.66 43.5122 10.7137 43.5035 10.766C43.4949 10.8183 43.4668 10.8653 43.4251 10.8975C42.8623 11.2749 36.6207 17.6991 34.2701 20.1428C34.2491 20.1642 34.2238 20.1808 34.196 20.1916C34.1681 20.2023 34.1383 20.207 34.1085 20.2052Z"
                                    fill="white"
                                  />
                                </svg>
                              </i>
                              <b>01</b>
                              <span className="feature-bg-border-1" />
                              <span className="feature-bg-border-2" />
                              <span className="feature-bg-border-3" />
                              <span className="feature-bg-border-4" />
                            </div>
                          </div>
                          <div className="feature-list-4-content">
                            <h4 className="title">{item.pointone.title}</h4>
                            <p>
                            {item.pointone.description}
                            </p>
                          </div>
                          <div className="feature-4-shape-1 d-none d-md-block">
                            <svg
                              className="line-dash-path"
                              width={38}
                              height={122}
                              viewBox="0 0 38 122"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.279297 1C41.9846 20.0005 55.1988 87.9525 2.74393 121.294"
                                stroke="#A7ACB3"
                                strokeDasharray="4 4"
                              />
                            </svg>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="feature-list-4-item p-relative pl-130 d-flex">
                          <div className="feature-list-4-icon ">
                            <div className="feature-list-bg p-relative">
                              <i>
                                <svg
                                  width={44}
                                  height={40}
                                  viewBox="0 0 44 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g style={{ mixBlendMode: "multiply" }}>
                                    <path
                                      d="M4.87714 18.8519C-4.16478 32.8161 8.76877 40 19.6928 40C30.4338 40 42.5804 35.9136 42.5804 23.3559C42.575 -8.96311 20.3949 -5.10428 4.87714 18.8519Z"
                                      fill="#4260FF"
                                    />
                                  </g>
                                  <path
                                    d="M1.09435 32.563C1.05346 32.563 1.01347 32.5509 0.979401 32.5281C0.945334 32.5053 0.918713 32.4729 0.902885 32.4349C0.887057 32.3968 0.882728 32.355 0.890444 32.3145C0.898161 32.274 0.917578 32.2367 0.946252 32.2073L9.1723 23.9395C9.21224 23.9178 9.25821 23.9101 9.30298 23.9176C9.34774 23.9251 9.38877 23.9474 9.4196 23.981C9.45043 24.0145 9.46932 24.0575 9.4733 24.1031C9.47727 24.1487 9.46611 24.1943 9.44156 24.2328L1.24245 32.5033C1.22298 32.5227 1.19985 32.538 1.17441 32.5483C1.14897 32.5585 1.12174 32.5635 1.09435 32.563Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M34.1085 20.2052C34.082 20.2053 34.0557 20.2001 34.0312 20.1899C34.0067 20.1796 33.9844 20.1645 33.9658 20.1455C33.9276 20.1064 33.9063 20.0537 33.9062 19.9989C33.9063 19.944 33.9276 19.8914 33.9658 19.8522C34.3131 19.4911 42.4826 11.0306 43.1962 10.55C43.2418 10.5237 43.2957 10.5159 43.3469 10.5281C43.398 10.5403 43.4427 10.5717 43.4717 10.6159C43.5008 10.66 43.5122 10.7137 43.5035 10.766C43.4949 10.8183 43.4668 10.8653 43.4251 10.8975C42.8623 11.2749 36.6207 17.6991 34.2701 20.1428C34.2491 20.1642 34.2238 20.1808 34.196 20.1916C34.1681 20.2023 34.1383 20.207 34.1085 20.2052Z"
                                    fill="white"
                                  />
                                </svg>
                              </i>
                              <b>02</b>
                              <span className="feature-bg-border-1" />
                              <span className="feature-bg-border-2" />
                              <span className="feature-bg-border-3" />
                              <span className="feature-bg-border-4" />
                            </div>
                          </div>
                          <div className="feature-list-4-content">
                            <h4 className="title">{item.pointtwo.title}</h4>
                            <p>
                            {item.pointtwo.description}
                            </p>
                          </div>
                          <div className="feature-4-shape-2 d-none d-md-block">
                            <svg
                              className="line-dash-path"
                              width={42}
                              height={122}
                              viewBox="0 0 42 122"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M41.3076 1.22192C-1.33493 18.0137 -18.0874 85.181 32.5507 121.222"
                                stroke="#A7ACB3"
                                strokeDasharray="4 4"
                              />
                            </svg>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="feature-list-4-item  d-flex">
                          <div className="feature-list-4-icon ">
                            <div className="feature-list-bg p-relative">
                              <i>
                                <svg
                                  width={44}
                                  height={40}
                                  viewBox="0 0 44 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g style={{ mixBlendMode: "multiply" }}>
                                    <path
                                      d="M4.87714 18.8519C-4.16478 32.8161 8.76877 40 19.6928 40C30.4338 40 42.5804 35.9136 42.5804 23.3559C42.575 -8.96311 20.3949 -5.10428 4.87714 18.8519Z"
                                      fill="#4260FF"
                                    />
                                  </g>
                                  <path
                                    d="M1.09435 32.563C1.05346 32.563 1.01347 32.5509 0.979401 32.5281C0.945334 32.5053 0.918713 32.4729 0.902885 32.4349C0.887057 32.3968 0.882728 32.355 0.890444 32.3145C0.898161 32.274 0.917578 32.2367 0.946252 32.2073L9.1723 23.9395C9.21224 23.9178 9.25821 23.9101 9.30298 23.9176C9.34774 23.9251 9.38877 23.9474 9.4196 23.981C9.45043 24.0145 9.46932 24.0575 9.4733 24.1031C9.47727 24.1487 9.46611 24.1943 9.44156 24.2328L1.24245 32.5033C1.22298 32.5227 1.19985 32.538 1.17441 32.5483C1.14897 32.5585 1.12174 32.5635 1.09435 32.563Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M34.1085 20.2052C34.082 20.2053 34.0557 20.2001 34.0312 20.1899C34.0067 20.1796 33.9844 20.1645 33.9658 20.1455C33.9276 20.1064 33.9063 20.0537 33.9062 19.9989C33.9063 19.944 33.9276 19.8914 33.9658 19.8522C34.3131 19.4911 42.4826 11.0306 43.1962 10.55C43.2418 10.5237 43.2957 10.5159 43.3469 10.5281C43.398 10.5403 43.4427 10.5717 43.4717 10.6159C43.5008 10.66 43.5122 10.7137 43.5035 10.766C43.4949 10.8183 43.4668 10.8653 43.4251 10.8975C42.8623 11.2749 36.6207 17.6991 34.2701 20.1428C34.2491 20.1642 34.2238 20.1808 34.196 20.1916C34.1681 20.2023 34.1383 20.207 34.1085 20.2052Z"
                                    fill="white"
                                  />
                                </svg>
                              </i>
                              <b>03</b>
                              <span className="feature-bg-border-1" />
                              <span className="feature-bg-border-2" />
                              <span className="feature-bg-border-3" />
                              <span className="feature-bg-border-4" />
                            </div>
                          </div>
                          <div className="feature-list-4-content">
                            <h4 className="title">{item.pointthree.title}</h4>
                            <p>
                             {item.pointthree.description}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 align-self-end">
                  <div className="feature-inner-gallery">
                    <div className="feature-inner-thumb">
                      <img src={item.image} alt="" />
                    </div>
                    {/* <div className="feature-inner-shape d-none d-md-block">
                      <img
                        className="feature-inner-shape-1"
                        src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/feature/inner/feature-inner-social-1.png"
                        alt=""
                      />
                      <img
                        className="feature-inner-shape-2"
                        src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/feature/inner/feature-inner-social-2.png"
                        alt=""
                      />
                      <img
                        className="feature-inner-shape-3"
                        src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/feature/inner/feature-inner-social-3.png"
                        alt=""
                      />
                      <img
                        className="feature-inner-shape-4"
                        src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/feature/inner/feature-inner-social-4.png"
                        alt=""
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Service;
