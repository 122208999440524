import React, { useEffect } from 'react';
import "./header.css"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
const Header = ({bg}) => {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [Nav, setNav] = React.useState();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  
  console.log(Nav)

  useEffect(()=>{
    if(Nav==0){
      navigate("/")
    }else if(Nav==1 ){
      navigate("/aboutus")
    }else if(Nav==2 ){
      navigate("/services")
    }else if(Nav==3 ){
      
      navigate("/projects")
    }else if(Nav==4 ){
      navigate("/contactus")
    }

  },[Nav])

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
    
      <List>
        {['Home', 'About us', 'Service','Our Project','Contact Us'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>{
              setNav(index)
            }}>
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

    return ( 
    
    <>{matches?
    <div className="main-header" style={{marginTop:10}}>
    <div className="custom-container">
      <div id="header-sticky" className="header-bg-4">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-6 col-sm-5 col-6">
       
            <div className="tplogo__area">
           
              <a href="/">
                <img
                  // src="./image/logo.png"
                  src="./image/logo.png"
                  alt="logo"
                  style={{marginLeft:130}}
                />

              </a>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6  d-none d-xl-block">
            <div className="tpmenu__area main-mega-menu">
              <nav className="tp-main-menu-content">
                <ul className="tp-onepage-menu">
                  <li className="has-dropdown has-mega-menu">
                  <Link to="/">Home</Link>
                
                  </li>
                  <li>
                    {/* <a href="#about-one-page">About</a> */}
                    <Link to="/aboutus">About Us</Link>
                  </li>
                  <li>
                  <Link to="/services">Service</Link>
                  </li>
                  <li>
                  <Link to="/projects">Our Projects</Link>
                  </li>
                 
                  <li>
                  <Link to="/contactus">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </div>:
  
  <div className="main-header" style={{marginTop:10}}>
    <div className="custom-container">
      <div id="header-sticky" className="header-bg-4">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-6 col-sm-5 col-6">
            <div className="tplogo__area">

            <IconButton aria-label="delete" onClick={toggleDrawer(true)} >
            <MenuIcon />
            </IconButton>
              <a href="index.html">
                <img
                  // src="./image/logo.png"
                  src="./image/logo.png"
                  alt="logo"
                  style={{marginLeft:130,marginTop:-50}}
                />

              </a>
            </div>
          </div>


          <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
         
       
        </div>
      </div>
    </div>
  </div>}</>
   );
}
 
export default Header;