import React, { useEffect, useState } from "react";
import Slider from "./slider/slider";
import Footer from "./footer/footer";
import Header from "./header/header";
import "./main.css";
import classNames from "classnames";
import useSticky from "./useSticky";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./HomePage/homepage";
import AboutUs from "./About/about";
import Service from "./service/service";
import TeamCTO from "../Team/CTO/cto";
import TeamSaimul from "../Team/Saimul/saimul";
import TeamCEO from "../Team/CEO/CEO";
import Projects from "./Projects/projects";
import ContactUs from "./contactus/contact";
import TeamHuman from "../Team/Human/human";

const Main = () => {
  const { sticky, stickyRef } = useSticky();
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      {screenLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 200 }}
        >
          {" "}
          <img src="./image/preloader.gif" alt="loading..." />{" "}
        </div>
      ) : (
        <>
          <>
      
            <Router>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/services" element={<Service />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/team/cto" element={<TeamCTO />} />
                <Route path="/team/saimul" element={<TeamSaimul />} />
                <Route path="/team/ceo" element={<TeamCEO />} />
                <Route path="/team/faisal" element={<TeamHuman />} />
              </Routes>
            </Router>
           
          </>
        </>
      )}
    </>
  );
};

export default Main;
