import React from "react";
import "./features.css";
const Features = () => {
  return (
    <section className="dalazu demo-screen-demo " id="features">
      <div className="container text-center">
        <div className="heading-subtitle">
          <span className="tx-primary tx-16 fw-semibold">Features</span>
        </div>
        <h1 className="wow fadeInUp reveal active">
          Our <span className="tx-primary">Features</span>{" "}
        </h1>
        <div className="row align-items-center mt-5">
          <div className="col-lg-4">
            <div className="card bg-transparent shadow-none">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 text-center">
                    <div className="mb-2">
                      <span className="avatar bg-primary-gradient1 text-white br-style">
                        <i className="fe fe-sun" />
                      </span>
                    </div>
                    <h5 className="mb-2">Versatile Development Ecosystem:</h5>
                    <p className="mb-0 op-8">
                    Our software farm provides a comprehensive and versatile development ecosystem, equipped with a wide range of programming languages, frameworks, and tools. Whether you're developing web applications, mobile apps, or enterprise solutions, our platform supports diverse technologies to meet the evolving needs of modern software development
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card bg-transparent shadow-none">
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1 text-center">
                    <div className="mb-2">
                      <span className="avatar bg-secondary-gradient text-white br-style">
                        <i className="fe fe-toggle-left" />
                      </span>
                    </div>
                    <h5 className="mb-2">Collaborative Development Environment:</h5>
                    <p className="mb-0 op-8">
                    Foster seamless collaboration among your development teams with our integrated and collaborative environment. Our software farm includes advanced version control systems, real-time collaboration tools, and project management features. From code sharing to collaborative debugging, we empower teams to work efficiently and produce high-quality software products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <img
              src="https://spruko.com/demo/hosting/hostma/demo/img/png/4.png"
              className="img-fluid"
              alt="img"
              width={300}
            />
          </div>
          <div className="col-lg-4">
            <div className="card bg-transparent shadow-none">
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1 text-center">
                    <div className="mb-2">
                      <span className="avatar bg-success-gradient text-white br-style">
                        <i className="fe fe-layout" />
                      </span>
                    </div>
                    <h5 className="mb-2">Automated Continuous Integration and Deployment (CI/CD):</h5>
                    <p className="mb-0 op-8">
                    Boost your software development lifecycle with our automated CI/CD pipelines. Our software farm streamlines the integration, testing, and deployment processes, ensuring rapid and reliable releases. With automated testing frameworks and deployment pipelines, your team can focus on innovation while maintaining code quality and project stability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card bg-transparent shadow-none">
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1 text-center">
                    <div className="mb-2">
                      <span className="avatar bg-danger-gradient text-white br-style">
                        <i className="fe fe-settings" />
                      </span>
                    </div>
                    <h5 className="mb-2">Scalable Infrastructure and Resource Management:</h5>
                    <p className="mb-0 op-8">
                    Scale your projects effortlessly with our software farm's robust infrastructure and resource management capabilities. From dynamic resource allocation to auto-scaling features, we optimize your development environment for performance and cost efficiency. Whether you're a startup with limited resources or an enterprise with complex infrastructure needs, our platform adapts to your project's scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
