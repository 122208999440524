

import React,{useEffect} from 'react';
import OurService from '../ourservice/ourservice';
import Features from '../features/features';
import Support from '../support/support';
import OurClient from '../ourclient/ourclient';
import ContactUs from '../contactus/contact';
import classNames from "classnames";
import useSticky from "../useSticky";
import Slider from "../slider/slider";
import Footer from "../footer/footer";
import Header from "../header/header";
import { LiveChatLoaderProvider, Messenger } from 'react-live-chat-loader'


const Homepage = () => {
    const { sticky, stickyRef } = useSticky();

  
    return (   <>
    
    <nav ref={stickyRef} className={classNames("nav", { sticky })}>
              <Header />
            </nav>
            <div
              style={{
                height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
              }}
            />
            <div style={{ height: "100vh" }}>
              <Slider  bg={"./image/sl.jpg"} />
            </div>
    
       <OurService />
    <Features />
    <Support />
    <OurClient />
    <LiveChatLoaderProvider
        provider="messenger"
        providerKey="161840493681010"
        appID="3791022987879979"
        locale="en_US"
      >
    
        <Messenger   greeting="Welcome! How can we assist you?" />
      </LiveChatLoaderProvider>
 


   
 
    <Footer/>
    </> );
}
 
export default Homepage;