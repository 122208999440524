import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./cto.css"
import { Box, Typography } from '@mui/material';
import CTORes from './ctores';
import SaimulRes from '../Saimul/saimulres';
const TeamCTO = () => {
  const matches = useMediaQuery('(min-width:600px)');
    return ( <> {matches? <>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
        integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP"
        crossOrigin="anonymous"
      />
      <div className='ttt' style={{display:"flex" ,justifyContent:"center",marginTop:200}}>
      <main className="profile">
        <div className="profile-bg" />
        <section className="container">
          <aside className="profile-image">
            
          </aside>
          <section className="profile-info">
            <h1 className="first-name">Angela</h1>
            <h1 className="second-name">Yun He</h1>
            <h2>ABOUT</h2>
            <p>
              hello hello, I'm angela, artist and developer 🌼 student at stanford;
              intern at zynga 🌱 happy to be here! 🌿 let's code the best we can!
            </p>
            <aside className="social-media-icons">
              <a href="https://twitter.com/zephybite" target="_blank">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://codepen.io/zephyo" target="_blank">
                <i className="fab fa-codepen" />
              </a>
              <a href="https://github.com/zephyo" target="_blank">
                <i className="fab fa-github" />
              </a>
              <a href="https://medium.com/@zephyo" target="_blank">
                <i className="fab fa-medium" />
              </a>
            </aside>
          </section>
        </section>
        <section className="statistics">
          <button className="icon arrow left" />
          <button className="icon arrow right" />
          <p>
            <strong>29</strong> Followers
          </p>
          <p>
            <strong>184</strong> Following
          </p>
          <p>
            <strong>6</strong> Likes
          </p>
        </section>
        <button className="icon close" />
      </main>
      </div>
      
    </>:<CTORes/>}



      </> );
}
 
export default TeamCTO;