import React from "react";
import "./about.css";
import OurTeam from "./team";
import useMediaQuery from "@mui/material/useMediaQuery";

import classNames from "classnames";
import useSticky from "../useSticky";
import Slider from "../slider/slider";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Box, Typography } from "@mui/material";
const AboutUs = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const { sticky, stickyRef } = useSticky();
  return (
    <>
      <nav ref={stickyRef} className={classNames("nav", { sticky })}>
        <Header />
      </nav>
      <div
        style={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
        }}
      />
      <div style={{ height: "100vh" }}>
        <Slider bg={"./image/about.png"} />
      </div>
      <div className="mission-area pb-120 pt-100" id="our-misson">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="mission-content ">
                <span>
                  <img
                    src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/about-5-shape-1.svg"
                    alt=""
                  />
                </span>
                <p>
                  𝗧𝗼 𝗲𝗺𝗽𝗼𝘄𝗲𝗿 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀𝗲𝘀 𝗮𝗻𝗱 𝗶𝗻𝗱𝗶𝘃𝗶𝗱𝘂𝗮𝗹𝘀 𝘄𝗶𝘁𝗵 𝗰𝘂𝘁𝘁𝗶𝗻𝗴-𝗲𝗱𝗴𝗲
                  𝘁𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝘆 𝘀𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀 𝘁𝗵𝗮𝘁 𝗱𝗿𝗶𝘃𝗲 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻, 𝗲𝗳𝗳𝗶𝗰𝗶𝗲𝗻𝗰𝘆, 𝗮𝗻𝗱
                  𝘀𝘂𝗰𝗰𝗲𝘀𝘀. 𝗪𝗲 𝗮𝗿𝗲 𝗰𝗼𝗺𝗺𝗶𝘁𝘁𝗲𝗱 𝘁𝗼 𝗱𝗲𝗹𝗶𝘃𝗲𝗿𝗶𝗻𝗴 𝗲𝘅𝗰𝗲𝗽𝘁𝗶𝗼𝗻𝗮𝗹 𝘀𝗼𝗳𝘁𝘄𝗮𝗿𝗲,
                  𝗱𝗲𝘀𝗶𝗴𝗻, 𝗮𝗻𝗱 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗺𝗮𝗿𝗸𝗲𝘁𝗶𝗻𝗴 𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀, 𝘁𝗮𝗶𝗹𝗼𝗿𝗲𝗱 𝘁𝗼 𝘁𝗵𝗲 𝘂𝗻𝗶𝗾𝘂𝗲
                  𝗻𝗲𝗲𝗱𝘀 𝗼𝗳 𝗼𝘂𝗿 𝗰𝗹𝗶𝗲𝗻𝘁𝘀. 𝗢𝘂𝗿 𝗺𝗶𝘀𝘀𝗶𝗼𝗻 𝗶𝘀 𝘁𝗼 𝗯𝗲 𝘁𝗵𝗲 𝘁𝗿𝘂𝘀𝘁𝗲𝗱 𝗽𝗮𝗿𝘁𝗻𝗲𝗿
                  𝘁𝗵𝗮𝘁 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗶𝗱𝗲𝗮𝘀 𝗶𝗻𝘁𝗼 𝗿𝗲𝗮𝗹𝗶𝘁𝘆, 𝗼𝗻𝗲 𝗽𝗿𝗼𝗷𝗲𝗰𝘁 𝗮𝘁 𝗮 𝘁𝗶𝗺𝗲
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission-shape p-relative d-none d-lg-block">
                <div className="mission-shape-1">
                  <img
                    src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/smill.png"
                    alt="triangle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mission-two">
            <div className="row">
              <div className="col-lg-4">
                <div className="mission-shape p-relative  d-none d-lg-block">
                  <div className="mission-shape-2">
                    <img
                      src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/mission-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div className="mission-shape-3">
                    <img
                      src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/mission-shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="mission-content">
                  <span>
                    <img
                      src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/about-5-shape-2.svg"
                      alt=""
                    />
                  </span>
                  <p>
                    𝗧𝗼 𝗯𝗲 𝘁𝗵𝗲 𝗳𝗼𝗿𝗲𝗳𝗿𝗼𝗻𝘁 𝗼𝗳 𝘁𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗰𝗮𝗹 𝗲𝘃𝗼𝗹𝘂𝘁𝗶𝗼𝗻, 𝗿𝗲𝗱𝗲𝗳𝗶𝗻𝗶𝗻𝗴
                    𝗶𝗻𝗱𝘂𝘀𝘁𝗿𝗶𝗲𝘀 𝗮𝗻𝗱 𝘀𝗲𝘁𝘁𝗶𝗻𝗴 𝗻𝗲𝘄 𝘀𝘁𝗮𝗻𝗱𝗮𝗿𝗱𝘀 𝗶𝗻 𝘀𝗼𝗳𝘁𝘄𝗮𝗿𝗲, 𝗱𝗲𝘀𝗶𝗴𝗻,
                    𝗮𝗻𝗱 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗺𝗮𝗿𝗸𝗲𝘁𝗶𝗻𝗴. 𝗪𝗲 𝗲𝗻𝘃𝗶𝘀𝗶𝗼𝗻 𝗮 𝗳𝘂𝘁𝘂𝗿𝗲 𝘄𝗵𝗲𝗿𝗲 𝗗𝗮𝗹𝗮𝘇𝘂
                    𝗧𝗲𝗰𝗵 𝗶𝘀 𝘀𝘆𝗻𝗼𝗻𝘆𝗺𝗼𝘂𝘀 𝘄𝗶𝘁𝗵 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻, 𝗰𝗿𝗲𝗮𝘁𝗶𝘃𝗶𝘁𝘆, 𝗮𝗻𝗱
                    𝗲𝘅𝗰𝗲𝗹𝗹𝗲𝗻𝗰𝗲, 𝗿𝗲𝗰𝗼𝗴𝗻𝗶𝘇𝗲𝗱 𝗳𝗼𝗿 𝗼𝘂𝗿 𝗮𝗯𝗶𝗹𝗶𝘁𝘆 𝘁𝗼 𝗮𝗱𝗮𝗽𝘁 𝘁𝗼 𝘁𝗵𝗲
                    𝗲𝘃𝗲𝗿-𝗰𝗵𝗮𝗻𝗴𝗶𝗻𝗴 𝗱𝗶𝗴𝗶𝘁𝗮𝗹 𝗹𝗮𝗻𝗱𝘀𝗰𝗮𝗽𝗲 𝗮𝗻𝗱 𝗺𝗮𝗸𝗲 𝗮 𝗽𝗼𝘀𝗶𝘁𝗶𝘃𝗲 𝗶𝗺𝗽𝗮𝗰𝘁
                    𝗼𝗻 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀𝗲𝘀 𝗮𝗻𝗱 𝗰𝗼𝗺𝗺𝘂𝗻𝗶𝘁𝗶𝗲𝘀 𝘄𝗼𝗿𝗹𝗱𝘄𝗶𝗱𝗲
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {matches ? (
        <Box display="flex" justifyContent="center">
          <img src="/image/chairmen.png" style={{ padding: 10 }} />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center">
            <img
              src="/image/singleceo.png"
              style={{ width: 300, height: 300 }}
            />
          </Box>

          <Box>
            <Typography
              variant="h5"
              textAlign="center"
              style={{ marginTop: 20 }}
            >
              {" "}
              Managing Director's Message
            </Typography>
            '
            <Typography
              variant="h5"
              textAlign="center"
              style={{ marginTop: 20 }}
            >
              𝗗𝗲𝗮𝗿 𝗩𝗮𝗹𝘂𝗲𝗱 𝗣𝗮𝗿𝘁𝗻𝗲𝗿𝘀, 𝗖𝗹𝗶𝗲𝗻𝘁𝘀, 𝗮𝗻𝗱 𝗧𝗲𝗮𝗺 𝗠𝗲𝗺𝗯𝗲𝗿𝘀,
            </Typography>
            <Typography textAlign={"center"} style={{ padding: 10 }}>
              𝗪𝗲𝗹𝗰𝗼𝗺𝗲 𝘁𝗼 𝘁𝗵𝗲 𝘄𝗼𝗿𝗹𝗱 𝗼𝗳 𝗗𝗮𝗹𝗮𝘇𝘂 𝗧𝗲𝗰𝗵, 𝘄𝗵𝗲𝗿𝗲 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻 𝗸𝗻𝗼𝘄𝘀 𝗻𝗼
              𝗯𝗼𝘂𝗻𝗱𝘀, 𝗰𝗿𝗲𝗮𝘁𝗶𝘃𝗶𝘁𝘆 𝗵𝗮𝘀 𝗻𝗼 𝗹𝗶𝗺𝗶𝘁𝘀, 𝗮𝗻𝗱 𝘁𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝘆 𝗶𝘀 𝗮 𝗰𝗮𝗻𝘃𝗮𝘀 𝗳𝗼𝗿
              𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻. 𝗜'𝗺 𝘁𝗵𝗿𝗶𝗹𝗹𝗲𝗱 𝘁𝗼 𝘀𝗵𝗮𝗿𝗲 𝘄𝗶𝘁𝗵 𝘆𝗼𝘂 𝘁𝗵𝗲 𝗲𝘀𝘀𝗲𝗻𝗰𝗲 𝗼𝗳 𝗼𝘂𝗿
              𝗷𝗼𝘂𝗿𝗻𝗲𝘆 𝗮𝗻𝗱 𝘁𝗵𝗲 𝗽𝗮𝘀𝘀𝗶𝗼𝗻 𝘁𝗵𝗮𝘁 𝗱𝗿𝗶𝘃𝗲𝘀 𝘂𝘀 𝗳𝗼𝗿𝘄𝗮𝗿𝗱.
            </Typography>
            <Typography textAlign={"center"} style={{ padding: 10 }}>
              𝗗𝗮𝗹𝗮𝘇𝘂 𝗧𝗲𝗰𝗵 𝗶𝘀 𝗻𝗼𝘁 𝗷𝘂𝘀𝘁 𝗮 𝗰𝗼𝗺𝗽𝗮𝗻𝘆; 𝗶𝘁'𝘀 𝗮 𝘁𝗲𝘀𝘁𝗮𝗺𝗲𝗻𝘁 𝘁𝗼 𝘁𝗵𝗲 𝗽𝗼𝘄𝗲𝗿
              𝗼𝗳 𝗱𝗿𝗲𝗮𝗺𝘀, 𝗱𝗲𝗱𝗶𝗰𝗮𝘁𝗶𝗼𝗻, 𝗮𝗻𝗱 𝘁𝗵𝗲 𝘂𝗻𝘄𝗮𝘃𝗲𝗿𝗶𝗻𝗴 𝗯𝗲𝗹𝗶𝗲𝗳 𝗶𝗻 𝘁𝗵𝗲 𝗽𝗼𝘁𝗲𝗻𝘁𝗶𝗮𝗹
              𝗼𝗳 𝘁𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝘆 𝘁𝗼 𝘀𝗵𝗮𝗽𝗲 𝗮 𝗯𝗲𝘁𝘁𝗲𝗿 𝗳𝘂𝘁𝘂𝗿𝗲. 𝗦𝗶𝗻𝗰𝗲 𝗼𝘂𝗿 𝗶𝗻𝗰𝗲𝗽𝘁𝗶𝗼𝗻, 𝘄𝗲'𝘃𝗲
              𝗲𝗺𝗯𝗮𝗿𝗸𝗲𝗱 𝗼𝗻 𝗮 𝗿𝗲𝗺𝗮𝗿𝗸𝗮𝗯𝗹𝗲 𝗮𝗱𝘃𝗲𝗻𝘁𝘂𝗿𝗲, 𝗼𝗻𝗲 𝘁𝗵𝗮𝘁'𝘀 𝗱𝗲𝗳𝗶𝗻𝗲𝗱 𝗯𝘆 𝗮
              𝗿𝗲𝗹𝗲𝗻𝘁𝗹𝗲𝘀𝘀 𝗽𝘂𝗿𝘀𝘂𝗶𝘁 𝗼𝗳 𝗲𝘅𝗰𝗲𝗹𝗹𝗲𝗻𝗰𝗲 𝗮𝗻𝗱 𝗮 𝗰𝗼𝗺𝗺𝗶𝘁𝗺𝗲𝗻𝘁 𝘁𝗼 𝗽𝘂𝘀𝗵𝗶𝗻𝗴 𝘁𝗵𝗲
              𝗯𝗼𝘂𝗻𝗱𝗮𝗿𝗶𝗲𝘀 𝗼𝗳 𝘄𝗵𝗮𝘁'𝘀 𝗽𝗼𝘀𝘀𝗶𝗯𝗹𝗲.
            </Typography>
            <Typography textAlign={"center"} style={{ padding: 10 }}>
              𝗔𝘁 𝗗𝗮𝗹𝗮𝘇𝘂 𝗧𝗲𝗰𝗵, 𝘄𝗲'𝗿𝗲 𝗺𝗼𝗿𝗲 𝘁𝗵𝗮𝗻 𝗮 𝘁𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝘆 𝗽𝗿𝗼𝘃𝗶𝗱𝗲𝗿; 𝘄𝗲'𝗿𝗲 𝗮
              𝗽𝗮𝗿𝘁𝗻𝗲𝗿 𝗶𝗻 𝘆𝗼𝘂𝗿 𝗷𝗼𝘂𝗿𝗻𝗲𝘆 𝘁𝗼 𝘀𝘂𝗰𝗰𝗲𝘀𝘀. 𝗪𝗲 𝗹𝗼𝗼𝗸 𝗳𝗼𝗿𝘄𝗮𝗿𝗱 𝘁𝗼
              𝗰𝗼𝗹𝗹𝗮𝗯𝗼𝗿𝗮𝘁𝗶𝗻𝗴, 𝗶𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗻𝗴, 𝗮𝗻𝗱 𝗮𝗰𝗵𝗶𝗲𝘃𝗶𝗻𝗴 𝗿𝗲𝗺𝗮𝗿𝗸𝗮𝗯𝗹𝗲 𝗺𝗶𝗹𝗲𝘀𝘁𝗼𝗻𝗲𝘀
              𝘁𝗼𝗴𝗲𝘁𝗵𝗲𝗿. 𝗧𝗵𝗲 𝗳𝘂𝘁𝘂𝗿𝗲 𝗶𝘀 𝗹𝗶𝗺𝗶𝘁𝗹𝗲𝘀𝘀, 𝗮𝗻𝗱 𝘄𝗲'𝗿𝗲 𝗲𝘅𝗰𝗶𝘁𝗲𝗱 𝘁𝗼 𝗲𝘅𝗽𝗹𝗼𝗿𝗲 𝗶𝘁
              𝘄𝗶𝘁𝗵 𝘆𝗼𝘂.
            </Typography>
            <Typography textAlign={"center"} style={{ padding: 10 }}>
              𝗧𝗵𝗮𝗻𝗸 𝘆𝗼𝘂 𝗳𝗼𝗿 𝗯𝗲𝗶𝗻𝗴 𝗽𝗮𝗿𝘁 𝗼𝗳 𝘁𝗵𝗶𝘀 𝗶𝗻𝗰𝗿𝗲𝗱𝗶𝗯𝗹𝗲 𝘃𝗼𝘆𝗮𝗴𝗲. 𝗛𝗲𝗿𝗲'𝘀 𝘁𝗼 𝗮
              𝗳𝘂𝘁𝘂𝗿𝗲 𝗳𝗶𝗹𝗹𝗲𝗱 𝘄𝗶𝘁𝗵 𝗶𝗻𝗳𝗶𝗻𝗶𝘁𝗲 𝗽𝗼𝘀𝘀𝗶𝗯𝗶𝗹𝗶𝘁𝗶𝗲𝘀.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default AboutUs;
