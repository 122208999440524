import React from 'react';
import "./ctores.css"
const CEORes = () => {
    return ( 
      
    <div className='dalazu_Team_mem' style={{display:"flex" , justifyContent:"center"}}>
          <link
        href="https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css"
        rel="stylesheet"
      />
        <div className="profile-card">
    <div className="image">
      <img src="/image/ceo.jpg" alt="" class="profile-img" />
      
    </div>
    <div className="text-data">
      <span className="name">Sakib Chowdhury</span>
      <span className="job">Founder & CEO</span>
    </div>
    <div className="media-buttons">
      <a href="#" style={{ background: "#4267b2" }} className="link">
        <i className="bx bxl-facebook" />
      </a>
      <a href="#" style={{ background: "#1da1f2" }} className="link">
        <i className="bx bxl-twitter" />
      </a>
      <a href="#" style={{ background: "#e1306c" }} className="link">
        <i className="bx bxl-instagram" />
      </a>
      <a href="#" style={{ background: "#ff0000" }} className="link">
        <i className="bx bxl-youtube" />
      </a>
    </div>
    <div className="buttons">
      <button className="button">Subscribe</button>
      <button className="button">Message</button>
    </div>
    <div className="analytics">
      <div className="data">
        <i className="bx bx-heart" />
        <span className="number">60k</span>
      </div>
      <div className="data">
        <i className="bx bx-message-rounded" />
        <span className="number">20k</span>
      </div>
      <div className="data">
        <i className="bx bx-share" />
        <span className="number">12k</span>
      </div>
    </div>
  </div>
    </div>
   );
}
 
export default CEORes;