import React from "react";

import "./ourclient.css";
import { image } from "../data";
const OurClient = () => {
  return (
    <div className="container dclients">
      {" "}
      <div className="heading-section">
        {" "}
        <div className="heading-subtitle">
          {/* <span className="tx-primary tx-16 fw-semibold">Software Tools</span> */}
        </div>{" "}
        <div className="heading-title">
          Our Honourable
          <span className="tx-primary"> Clients</span>
        </div>{" "}
        <div className="heading-description">All Over World</div>{" "}
      </div>{" "}
      <div className="row justify-content-center">
        {" "}


        {image.map((item,index)=>{
          return(
<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
          {" "}
          <div className="card feature-card-15 text-center align-items-center">
            {" "}
            <div className="card-body">
              {" "}
              <img
                src={item.image}
                alt="img"
                width={150}
                className="tool-img img-fluid"
              />{" "}
              <img
                src="https://spruko.com/demo/hosting/hostma/dist/assets/images/png/apps/19.png"
                alt="img"
                width={150}
                className="tool-img-white img-fluid"
              />{" "}
            </div>{" "}
          </div>{" "}
        </div>
          )
        })}
        
   
      </div>{" "}
    </div>
  );
};

export default OurClient;
