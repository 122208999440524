import React from "react";
import "./projects.css";
import classNames from "classnames";
import useSticky from "../useSticky";
import Slider from "../slider/slider";
import Footer from "../footer/footer";

import Header from "../header/header";
import { Box, Button } from "@mui/material";

const Projects = () => {
  const { sticky, stickyRef } = useSticky();
  return (
    <>
      <nav ref={stickyRef} className={classNames("nav", { sticky })}>
        <Header />
      </nav>
      <div
        style={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
        }}
      />
      <div style={{ height: "100vh" }}>
        <Slider bg={"./image/project.png"} />
      </div>
      <>
        <section
          id="features-12"
          className="dp_bg shape--bg shape--white-500 pt-100 features-section division"
        >
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-5">
                <div
                  className="txt-block left-column wow fadeInRight"
                  style={{ visibility: "visible" }}
                >
                  <span className="section-id">Our Sister Concern </span>
                  <h2 className="s-46 w-700">
                    Smart solutions, By Dalazu Tech
                  </h2>
                  <ul className="simple-list">
                    <li className="list-item">
                      <p>
                        Dalazu is a Classfied Based E-Commerce Website
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="mb-0">
                       Dalazu Tracker is a In house Decoded Vahicle Tracking System
                      </p>
                    </li>
                  </ul>
                  {/* <a
                    className="btn btn-sm r-04 btn--tra-black hover--theme"
                    href="/demo-2#banner-3"
                  >
                    Get started now
                  </a> */}
                </div>
              </div>{" "}
              <div className="col-md-7">
                <div
                  className="fbox-12-wrapper wow fadeInLeft"
                  style={{ visibility: "visible" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        id="fb-12-1"
                        className="fbox-12 bg--white-100 block-shadow r-12 mb-30" style={{height: 290}}
                      >
                        <div className="fbox-ico ico-50">
                          <div className="shape-ico color--theme">
                            <span className="flaticon-graphics" />
                            <svg
                              viewBox="0 0 200 200"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                                transform="translate(100 100)"
                              />
                            </svg>
                          </div>
                        </div>{" "}
                        <div className="fbox-txt">
                          <h5 className="s-19 w-700">Dalazu.com</h5>
                          <p>
                           Dalazu.com is a Classfied E-Commerce website
                          </p>
                       <Box display="flex" justifyContent="center">
                       <Button variant="outlined" href="https://dalazu.com"> Visit </Button>
                       </Box>
                        </div>
                      </div>
                     
                    </div>
                    <div className="col-md-6">
                      <div
                        id="fb-12-3"
                        className="fbox-12 bg--white-100 block-shadow r-12 mb-30"
                      >
                        <div className="fbox-ico ico-50">
                          <div className="shape-ico color--theme">
                            <span className="flaticon-idea" />
                            <svg
                              viewBox="0 0 200 200"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                                transform="translate(100 100)"
                              />
                            </svg>
                          </div>
                        </div>{" "}
                        <div className="fbox-txt">
                          <h5 className="s-19 w-700">Dalazu Tracker</h5>
                          <p>
                            Dalazu Tracker is a Custom based Iot Gps Tracking Solutions
                          </p>
                          <Box display="flex" justifyContent="center">
                       <Button variant="outlined" href="https://dalazutracker.com"> Visit </Button>
                       </Box>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>
      </>
      {/* ----------------------------------- */}
{/* 
      <section id="features-11" className=" dd_pt pt-100 features-section division">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-10 col-lg-9">
        <div className="section-title mb-70">
          <h2 className="s-50 w-700">
            We make your business gain more revenue at a glance
          </h2>
          <p className="s-21 color--grey">
            Ligula risus auctor tempus magna feugiat lacinia.
          </p>
        </div>
      </div>
    </div>
    <div className="fbox-wrapper">
      <div className="row row-cols-1 row-cols-md-2 rows-3">
        <div className="col">
          <div
            className="fbox-11 fb-1 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-graphics" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">Market Research</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div
            className="fbox-11 fb-2 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-idea" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">User Experience</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div
            className="fbox-11 fb-3 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-graphic" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">Digital Marketing</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div
            className="fbox-11 fb-4 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-wireframe" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">Web Development</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div
            className="fbox-11 fb-5 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-trophy" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">Brand Design Identity</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div
            className="fbox-11 fb-6 wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            <div className="fbox-ico-wrap">
              <div className="fbox-ico ico-50">
                <div className="shape-ico color--theme">
                  <span className="flaticon-search-engine-1" />
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>{" "}
            <div className="fbox-txt">
              <h6 className="s-22 w-700">SEO &amp; SMM Services</h6>
              <p>
                Porta semper lacus cursus feugiat primis ultrice ligula risus
                ociis auctor and tempus feugiat impedit felis cursus auctor
                augue mauris blandit ipsum
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>{" "}
  </div>{" "}
</section> */}

    </>
  );
};

export default Projects;
