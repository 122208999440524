import React from "react";
import "./ourservice.css";

const OurService = () => {
  return (
    // <section className="community section-padding style-4">
    //   <div className="container">
    //     <div className="section-head text-center style-4 ">
    //       <a
    //         href="#"
    //         style={{
    //           color: "#000000",
    //           marginRight: 10,
    //           textDecoration: "none",
    //         }}
    //       >
    //         <h1> Our Service</h1>
    //       </a>
    //     </div>
    //     <div className="community-content">
    //       <div className="community-card">
    //         <div className="icon">
    //           <i className="fab fa-facebook" />
    //         </div>
    //         <div className="inf">
    //           <h5>Facebook</h5>
    //         </div>
    //       </div>
    //       <div className="community-card">
    //         <div className="icon">
    //           <i className="fab fa-facebook-messenger" />
    //         </div>
    //         <div className="inf">
    //           <h5>Messenger</h5>
    //         </div>
    //       </div>
    //       <div className="community-card">
    //         <div className="icon">
    //           <i className="fab fa-whatsapp" />
    //         </div>
    //         <div className="inf">
    //           <h5>WhatsApp</h5>
    //         </div>
    //       </div>
    //       <div className="community-card">
    //         <div className="icon">
    //           <i className="fab fa-instagram" />
    //         </div>
    //         <div className="inf">
    //           <h5>Instagram</h5>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

<>
<section className="dservice process__area pt-120 pb-120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="tpsection__wrapper text-center mb-70">
          <div className="tpbanner__sub-title mb-15">
            <span>How we do it</span>
            <i>
              <svg
                width={124}
                height={38}
                viewBox="0 0 124 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <rect
                  width={124}
                  height={38}
                  fill="url(#pattern1)"
                  fillOpacity="0.08"
                />
                <defs>
                  <pattern
                    id="pattern1"
                    patternContentUnits="objectBoundingBox"
                    width={1}
                    height={1}
                  >
                    <use
                      xlinkHref="#image0_933_1323"
                      transform="translate(-0.0596774) scale(0.00612903 0.02)"
                    />
                  </pattern>
                  <image
                    id="image0_933_1323"
                    width={180}
                    height={50}
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAyCAYAAAD1JPH3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmVkYTJiM2ZhYywgMjAyMS8xMS8xNy0xNzoyMzoxOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUMyMzkwQTczMTM3MTFFRDg3NUZBOUZCRDk1MThFMTMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUMyMzkwQTYzMTM3MTFFRDg3NUZBOUZCRDk1MThFMTMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjFBQkIwMjIzMTA2MTFFREEzRkNBQzVFOTRFRTVERTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjFBQkIwMjMzMTA2MTFFREEzRkNBQzVFOTRFRTVERTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5lgZ3iAAAB2ElEQVR42uzd7U3CUBiA0Wr4DxvQDWQE4gS6gSO6ibhB2aBMoCG5ja83pVRaSSnnJE2/MII+t7386sPz21dxQ1ZpKdNSpPUynItL/nNd6rSc2t6n/Sot+TYTsJhgrJsU6TqLt7zS779EE3ad1vuwvwuDg5kF3QQao92E47fq3ICrQ/RN8LsQPBMPOob7lEV8j5o7z6Yj+Cbwzyx+U5orB32MdCvcwcFv0/ZLx5SmubrHAVCZ0lwW9CrEu07rcsC8k/GmNPk8vg7hx2P13K/4fYP+6LhdcjvR5wMgrmP0h5bXxdecmjadu1N0ffFuOxc/zzKcP65fhwQt5nkOgGJu08NH/1cEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDQIGkGDoEHQIGgQNIIGQYOgQdAgaAQNE7aY+Ptre7JSn6ct9VWe2UfQvx4XFuPbZ+fz1/5HsGPEHh831mw359bhWH6OCQf9Xvw8v67OQmyLcg6GfJ4Yd769Dvviv+yuXQ0N+tXf8aKpUtUz/rJlvWw5Pmf5RfIQ7vJV0fO55wvtTSL+3R+mQasTd4BlyzRpdeWBEKeL+aC+yt1d0PczDSpH/PJbjfi+RvUtwADssXzgKiX3gwAAAABJRU5ErkJggg=="
                  />
                </defs>
              </svg>
            </i>
          </div>
          <h2 className="tpsection__title">Helping others Succeed</h2>
        </div>
      </div>
    </div>
    <div className="tpprocess__border-bottom p-relative pb-45">
      <div className="tpprocess-shape-four d-none d-md-block">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/process-rocket.png"
          alt=""
        />
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="tpprocess__item p-relative mb-40">
            <div className="tpprocess__wrapper">
              <span className="tpprocess__count mb-25">1</span>
              <h4 className="tpprocess__title">
                Brainstorming and Creative <br /> ideas &amp; Research
              </h4>
            </div>
            <div className="tpprocess-shape-one d-none d-md-block">
              <svg
                width={112}
                height={15}
                viewBox="0 0 112 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="line-dash-path"
                  d="M1 8.56464C18.4695 1.84561 64.9267 -6.52437 111 13.7479"
                  stroke="#A6A8B0"
                  strokeDasharray="4 5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="tpprocess__item p-relative ml-30 mb-40">
            <div className="tpprocess__wrapper tpprocess__two">
              <span className="tpprocess__count mb-25">2</span>
              <h4 className="tpprocess__title">
                Fully-Automated Keyword <br /> Grouping
              </h4>
            </div>
            <div className="tpprocess-shape-two d-none d-lg-block">
              <svg
                width={112}
                height={15}
                viewBox="0 0 112 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="line-dash-path"
                  d="M1 6.43536C18.4695 13.1544 64.9267 21.5244 111 1.25212"
                  stroke="#A6A8B0"
                  strokeDasharray="4 5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="tpprocess__item p-relative ml-55 mb-40">
            <div className="tpprocess__wrapper tpprocess__three">
              <span className="tpprocess__count mb-25">3</span>
              <h4 className="tpprocess__title">
                Building the strategy to <br /> boost Your Sales
              </h4>
            </div>
            <div className="tpprocess-shape-three d-none d-md-block">
              <svg
                width={112}
                height={15}
                viewBox="0 0 112 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="line-dash-path"
                  d="M1 8.56464C18.4695 1.84561 64.9267 -6.52437 111 13.7479"
                  stroke="#A6A8B0"
                  strokeDasharray="4 5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="tpprocess__item d-flex justify-content-end mb-40">
            <div className="tpprocess__wrapper tpprocess__four">
              <span className="tpprocess__count mb-25">4</span>
              <h4 className="tpprocess__title">
                Helping to achieve more <br /> People
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


</>
  );
};

export default OurService;
