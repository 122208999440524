import React,{useState,useEffect} from "react";
import "./slider.css";
const Slider = ({bg}) => {
 


const [country, setCountry] = useState('UK');

useEffect(() => {
  const countries = ['BD', 'UAE', 'USA',"KSA"];
  let index = 0;

  const intervalId = setInterval(() => {
    index = (index + 1) % countries.length;
    setCountry(countries[index]);
  }, 3000); // Change country every 3 seconds

  return () => clearInterval(intervalId);
}, []);

return (
  <div className="banner-area banner-4-spaces pt-150">
    <div className="container">
      <div className="row">
        <div className="col-xxl-7 col-xl-10 col-lg-7">
          <div className="banner-4">
            <div className="banner-4-content">
              <h4 className="banner-4-title cd-headline clip is-full-width">
               Dalazu Tech<br />
               
                {/* <span className="cd-words-wrapper" style={{ width: "1000px" }}> */}
                
                  <b className="is-visible">For The Nation</b> <br/>
                {/* </span> */}
                in {country}
              </h4>
              <p>
                All in one  for your business to give real impact <br />{" "}
                consultancy and analysis.!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-4-shape d-none d-lg-block">
      <div className="banner-4-shape-one">
        <img
          src={bg}
          alt="" 

          style={{width:1100,height:900}}
        />
      </div>
      <div className="banner-4-shape-two">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-4-shape-1.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-three">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-4-shape-2.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-six">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-shape-4-1.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-seven">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-shape-4-2.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-eight">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-shape-4-3.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-nine">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-shape-4-4.png"
          alt=""
        />
      </div>
      <div className="banner-4-shape-ten">
        <img
          src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/shape/banner-shape-4-5.png"
          alt=""
        />
      </div>
    </div>
    </div>
  </div>




  );
};

export default Slider;
