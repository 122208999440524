export const image = [
  {
    image: "/image/OurClients/altaazej.jpg",
  },
  {
    image: "/image/OurClients/mission team.jpg",
  },
  {
    image: "/image/OurClients/pak commidties.jpg",
  },
  {
    image: "/image/OurClients/ctg fashion.jpg",
  },
  {
    image: "/image/OurClients/shafu shipping.jpg",
  },
  {
    image: "/image/OurClients/united.jpg",
  },
  {
    image: "/image/OurClients/victory sports arena.jpg",
  },
  {
    image: "/image/OurClients/axis overseas.jpg",
  },
  {
    image: "/image/OurClients/procare.jpg",
  },
  {
    image: "/image/OurClients/flyair solutions.jpg",
  },
];

export const features = [
  { title: "Graphics Design", describe: "dEMO dESCRIP" },
];

export const service = [
  {
    title: "App Development",
    image: "/image/web/app development.png",
    pointone: {
      title: "Conceptualize",
      description:
        " Brainstorm and design the app's user experience and functionality",
    },
    pointtwo: {
      title: "Develop ",
      description:
        "Code and build the app with cutting-edge technology and seamless integration.",
    },
    pointthree: {
      title: "Test & Launch",
      description:
        "Deploy the app and provide ongoing support for optimal performance. ",
    },
  },
  {
    title: "Digital Marketing",
    image: "/image/web/digital marketing.png",
    pointone: {
      title: "Strategize",
      description:
        "Analyze your brand and create a tailored digital marketing plan.",
    },
    pointtwo: {
      title: "Execute",
      description:
        " Implement targeted campaigns across digital channels to reach your audience. ",
    },
    pointthree: {
      title: "Optimize",
      description:
        "Continuously monitor and refine strategies for maximum ROI. ",
    },
  },
  { title: "Graphics Design", image: "/image/web/graphic designer.png",  pointone: {
    title: "Brainstorm",
    description:
      "Collaborate to understand your brand’s visual needs and objectives.",
  },
  pointtwo: {
    title: "Design",
    description:
      "Create stunning visuals that capture your brand's essence and message.",
  },
  pointthree: {
    title: "Deliver",
    description:
      "Provide finalized designs in various formats for all your branding needs.",
  }, },
  {
    title: "Software Development",
    image: "/image/web/software development.png",
    pointone: {
      title: "Analyze",
      description:
        "Understand your business needs to design customized software solutions.",
    },
    pointtwo: {
      title: "Develop",
      description:
        "Code and build software with precision and cutting-edge technology.",
    },
    pointthree: {
      title: "Deploy",
      description:
        " Implement and support software to ensure seamless operation.",
    },
  },
  { title: "Web Development", image: "/image/web/Web development.png" ,  pointone: {
    title: "Plan",
    description:
      " Collaborate to define website goals and user experience strategies.",
  },
  pointtwo: {
    title: "Design & Build",
    description:
      " Create a visually appealing and responsive website.",
  },
  pointthree: {
    title: "Launch",
    description:
      " Deploy and optimize the website for maximum performance.",
  },



 

},
{
  title: "Influencer Marketing",
  image: "/image/web/influencer marketing.png",
  pointone: {
    title: "Identify",
    description:
      "Find the perfect influencers that align with your brand values.",
  },
  pointtwo: {
    title: "Collaborate ",
    description:
      "Create engaging content with influencers to reach your target audience.",
  },
  pointthree: {
    title: "Amplify",
    description:
      "Monitor and optimize campaigns for maximum impact and ROI.",
  },
},

{
  title: "Photography",
  image: "/image/web/photography.png",
  pointone: {
    title: "Conceptualize",
    description:
      " Visualize the story and style you want to capture.",
  },
  pointtwo: {
    title: "Shoot ",
    description:
      "Use professional techniques to capture the perfect shot.",
  },
  pointthree: {
    title: "Edit",
    description:
      "Enhance and retouch photos to highlight your brand’s essence. ",
  },
},

{
  title: "Video Editing",
  image: "/image/web/video editing.png",
  pointone: {
    title: "Ingest & Organize",
    description:
      " Import and structure raw footage for efficient editing.",
  },
  pointtwo: {
    title: "Edit & Refine",
    description:
      " Craft a narrative by trimming, cutting, and arranging clips.",
  },
  pointthree: {
    title: "Final Touches",
    description:
      " Add effects, sound, and color correction for a polished video.",
  },
  
},
{
  title: "Videography",
  image: "/image/web/videography.png",
  pointone: {
    title: "Pre-production",
    description:
      "Plan and script your video to align with your brand message.",
  },
  pointtwo: {
    title: "Production",
    description:
      "Capture high-quality footage using professional techniques.",
  },
  pointthree: {
    title: "Post-production",
    description:
      "Edit and enhance the video for a compelling final product.",
  },
  
},
{
  title: "Internet Of Things",
  image: "/image/web/internet of things.png",
  pointone: {
    title: "Consult",
    description:
      " Evaluate your needs to identify IoT opportunities.",
  },
  pointtwo: {
    title: "Implement",
    description:
      " Integrate smart devices and systems into your operations.",
  },
  pointthree: {
    title: "Optimize",
    description:
      "Monitor and improve IoT systems for enhanced performance.",
  },
  
},
{
  title: "Cyber Security",
  image: "/image/web/cyber security.png",
  pointone: {
    title: "Assess",
    description:
      "Analyze your current security posture and identify vulnerabilities.",
  },
  pointtwo: {
    title: "Protect",
    description:
      "Implement robust security measures to safeguard your data.",
  },
  pointthree: {
    title: "Monitor",
    description:
      "Continuously track and respond to potential threats in real-time. ",
  },
  
},
];
