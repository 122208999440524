import { Box } from '@mui/material';
import React from 'react';
import "./bottom.css"

const Bottom = () => {
    return ( <Box style={{padding:5}}> 
    <Box className="dfooter">
    <h3 style={{ fontSize: 24, marginBottom: 20,color:"White" }}>Contact Us</h3>
        <p style={{color:"Gray"}}>Bangladesh Head Office</p>
        <p style={{fontSize: 16 ,color:"White"  }}>
          A Block Bus stand House no: 16
          <br />
         Halishahar ,Chattogram
          <br />
          <br />
          Phone: +8801876660640
          <br />
          Email: info@dalazutech.com
        </p>
        <p style={{color:"Gray"}}>USA Head Office</p>
        <p style={{ fontSize: 16 ,color:"White" }}>
        12330, Rosevelt Way NE,
          <br />
          Seattle Washington 98125, USA
          <br />
          <br />
          Phone: +1-206-557-0439
          <br />
          Email: info@dalazutech.com
        </p>
        <p style={{color:"Gray"}}>Dubai Head Office</p>
        <p style={{ fontSize: 16 ,color:"White"  }}>
        1801, Tower-B, Internet City, 
          <br />
          , Dubai, UAE

          <br />
          <br />
          Phone: +971525052831,
          Phone: +971551353716
          <br />
          Email: info@dalazutech.com
        </p>
  
        <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
       
        
       
      
        <a href="#" style={{ color: "#fff" }}>
          Copyright 2023, All rights reserved by Dalazu.
        </a>
       
      
      </div>
    </div>
    </Box>
    
      
    
    </Box> );
}
 
export default Bottom;