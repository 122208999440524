import React from "react";
import "./footer.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import Bottom from "./bottom";
const Footer = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (

    <>{matches?<footer className="dfooter" style={{  color: "#fff", padding: 50 }}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ width: "40%" }}>
        <h3 style={{ fontSize: 24, marginBottom: 20 }}>
          Dalazu Technologies
        </h3>
        <p style={{ fontSize: 16 }}>
        Our platform is designed to cater to the diverse needs of development teams, providing a versatile ecosystem that supports a multitude of programming languages and frameworks.
        </p>
      </div>
      <div style={{ width: "30%" }}>
        <h3 style={{ fontSize: 24, marginBottom: 20 }}>Quick Links</h3>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={{ marginBottom: 10 }}>
            <a href="/" style={{ color: "#fff", fontSize: 16 }}>
              Home
            </a>
          </li>
          <li style={{ marginBottom: 10 }}>
            <a href="/aboutus" style={{ color: "#fff", fontSize: 16 }}>
              About US
            </a>
          </li>
          <li style={{ marginBottom: 10 }}>
            <a href="#" style={{ color: "#fff", fontSize: 16 }}>
              Service
            </a>
          </li>
          <li style={{ marginBottom: 10 }}>
            <a href="#" style={{ color: "#fff", fontSize: 16 }}>
           Our Projects
            </a>
          </li>
          <li style={{ marginBottom: 10 }}>
            <a href="#" style={{ color: "#fff", fontSize: 16 }}>
             Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div style={{ width: "30%" }}>
        <h3 style={{ fontSize: 24, marginBottom: 20 }}>Contact Us</h3>
        <p style={{color:"black"}}>Bangladesh Head Office</p>
        <p style={{ fontSize: 16 }}>
          A Block Bus stand House no: 16
          <br />
         Halishahar ,Chattogram
          <br />
          <br />
          Phone: +8801876660640
          <br />
          Email: info@dalazutech.com
        </p>
        <p style={{color:"black"}}>USA Head Office</p>
        <p style={{ fontSize: 16 }}>
        12330, Rosevelt Way NE,
          <br />
          Seattle Washington 98125, USA
          <br />
          <br />
          Phone: +1-206-557-0439
          <br />
          Email: info@dalazutech.com
        </p>
        <p style={{color:"black"}}>Dubai Head Office</p>
        <p style={{ fontSize: 16 }}>
        1801, Tower-B, Internet City, 
          <br />
          , Dubai, UAE

          <br />
          <br />
          Phone: +971525052831,
          Phone: +971551353716
          <br />
          Email: info@dalazutech.com
        </p>
      </div>

      
    </div>
    <hr style={{ borderTop: "1px solid #fff", margin: "50px 0" }} />
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="#" style={{ color: "#fff", marginRight: 10 }}>
          Privacy Policy
        </a>
        <div
          style={{
            borderRight: "1px solid #fff",
            height: 20,
            margin: "0 10px",
          }}
        />
        <a href="#" style={{ color: "#fff", marginRight: 10 }}>
          Terms of Service
        </a>
        <div
          style={{
            borderRight: "1px solid #fff",
            height: 20,
            margin: "0 10px",
          }}
        />
        <a href="#" style={{ color: "#fff" }}>
          Copyright 2023, All rights reserved by Dalazu.
        </a>
        <div
          style={{
            borderRight: "1px solid #fff",
            height: 20,
            margin: "0 10px",
          }}
        />
        <span>Design &amp; Developed by </span> .
      </div>
    </div>
  </footer>:<Bottom/>}
    </>
    
  );
};

export default Footer;
